/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LimitCheckTypeEnum } from './LimitCheckTypeEnum';
import type { schemas__client__LimitType } from './schemas__client__LimitType';
export type BusinessEntityConfigSchema = {
    limitCheck?: LimitCheckTypeEnum;
    autoErpOrderCreate?: boolean;
    autoAcceptErpSupplierOrder?: BusinessEntityConfigSchema.auto_accept_erp_supplier_order;
    autoSolverAddRelatedModels?: boolean;
    addRelatedModels?: boolean;
    limitType?: schemas__client__LimitType;
};
export namespace BusinessEntityConfigSchema {
    export enum auto_accept_erp_supplier_order {
        TRUE = 'true',
        FALSE = 'false',
        CUSTOM = 'custom',
    }
}

